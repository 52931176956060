/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, func, shape, arrayOf, bool,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';

// redux, hooks, helpers
import { GRAPHQL_ENV } from '../../../gql';
import FlashDealUI from './FlashDealUI';
import findSkuByPartNumbers from '../../../gql/queries/findSkuByPartNumbers';
import HeroSkeleton from '../GraphqlSkeletonComponents/HeroSkeleton';

const isProductAvailable = (product) => product?.availability?.availabilityIndicator === 'true';

const isProductPublished = (product) => product?.availability?.published || false; // returning bool in gql

const FlashDealContentContainer = ({
    content, handleCloseTo, brand, heading, tracking, isCheckoutRecommendation, nbcuMovie,
}) => {
    const getPartNumbers = [];
    content.forEach((getSkuData) => {
        getPartNumbers.push(`"${getSkuData?.sku}"`);
    });

    const partNumbers = `[${getPartNumbers.join(',')}]`;

    const FLASH_DEAL_QUERY = findSkuByPartNumbers(
        brand.domain,
        partNumbers,
        `brand
        brandId
        id
        partNumber
        availability{
            availabilityIndicator
            published
        }
        name
        productType
        prices {
            currency
            type
            value
        }
        image {
            name
            path
        }
        parentProduct {
            seo {
                url
            }
        }`,
    );
    const { loading, error, data } = useQuery(FLASH_DEAL_QUERY);
    if (loading) {
        return  <HeroSkeleton height="200px" width="100%" />;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            jsError: error,
            component: 'FlashDealContentContainer.js',
            message: `Failed to fetch content data for: content type on brand ${brand}`,
            env: GRAPHQL_ENV,
        });
        handleCloseTo(false);
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            component: 'FlashDealContentContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        handleCloseTo(false);
        return null;
    }
    const getFlashData = data?.findSkuByPartNumbers?.filter((sku) => (isProductAvailable(sku) && isProductPublished(sku))) || [];

    return (
        <FlashDealUI isCheckoutRecommendation={isCheckoutRecommendation} tracking={tracking} content={getFlashData} heading={heading} handleCloseTo={handleCloseTo} nbcuMovieData={nbcuMovie} />
    );
};

FlashDealContentContainer.propTypes = {
    content: arrayOf(shape({
        sku: string.isRequired,
    })),
    brand: shape({
        domain: string,
    }).isRequired,
    handleCloseTo: func,
    heading: string.isRequired,
    tracking: shape({
        eventCategory: '',
        eventAction: '',
    }),
    isCheckoutRecommendation: bool,
    nbcuMovie: arrayOf(arrayOf(shape({
        sku: string.isRequired,
        nbcu_see_titles: string.isRequired,
        info_on_hover: string.isRequired,
    }))),
};
FlashDealContentContainer.defaultProps = {
    content: [],
    handleCloseTo: () => {},
    tracking: {},
    isCheckoutRecommendation: false,
    nbcuMovie: [],
};

export default (FlashDealContentContainer);
