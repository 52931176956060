/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, string, object, number,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    img: {
        width: '100%',
    },
    roundImg: {
        borderRadius: '3px',
    },
});

const imageTypes = ['t', 'z', 'l', 'c', 'x'];

const imageSrcBuilder = (imagePath, imageName, typeImg = '') => {
    let imageURL = `https:${imagePath}${imageName}`;

    if (typeImg !== '') {
        const imageTypeJPG = imageURL.lastIndexOf('.jpg');
        if (imageTypeJPG > 0) {
            const imageSrcType = imageURL.charAt(imageTypeJPG - 1);
            if (imageTypes.includes(imageSrcType)) {
                imageURL = imageURL.replace('t.jpg', `${typeImg}.jpg`);
            } else {
                imageURL = imageURL.replace('.jpg', `${typeImg}.jpg`);
            }
        } else {
            imageURL = `${imageURL}${typeImg}.jpg`;
        }
    } else {
        imageURL = `${imageURL}${typeImg}.jpg`;
    }

    return imageURL;
};

const ItemImage = (props) => {
    const {
        classes,
        roundedCorner,
        imagePath,
        imageName,
        typeImg,
        imagePathPmall,
        countBustPmallImg,
        size,
    } = props;
    let { src } = props;
    const imgClasses = (roundedCorner) ? `${classes.img} ${classes.roundImg}` : classes.img;
    if (imagePath && imageName) {
        src = imageSrcBuilder(imagePath, imageName, typeImg);
    }

    let imgPmallUrl = imagePathPmall;
    if (imagePathPmall) {
        imgPmallUrl = `${imagePathPmall}&cacheBust=${countBustPmallImg}`;
    }

    return (
        <img
            className={imgClasses}
            src={`${imgPmallUrl || src}${size}`}
            aria-hidden="true"
            alt="product"
        />
    );
};

ItemImage.defaultProps = {
    src: '',
    classes: {},
    roundedCorner: false,
    imagePath: '',
    imageName: '',
    typeImg: '', // type images: t, z, l, c, x
    imagePathPmall: '',
    countBustPmallImg: 0,
    size: '',
};

ItemImage.propTypes = {
    src: string,
    classes: object,
    roundedCorner: bool,
    imagePath: string,
    imageName: string,
    typeImg: string,
    imagePathPmall: string,
    countBustPmallImg: number,
    size: string,
};

export default withStyles(styles)(ItemImage);
